
import Home from './components/pages/Home'
import Boidvoid from './components/pages/Boidvoid';
import TheUniverse from './components/pages/TheUniverse';
import BoidColony from './components/pages/BoidColony';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Blog from './components/pages/Blog';
import Signup from './components/pages/Signup';
import Avatars from './components/pages/Avatars';


function App() {
  return (
    <div>

<Router>
    <Routes>

      <Route path="/" element={<Home />} />
      <Route path="/universe" element={<TheUniverse />} />
      <Route path="/boidvoid" element={<Boidvoid />} />
      <Route path="/boidcolony" element={<BoidColony />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/avatars" element={<Avatars />} />

    </Routes>
  </Router>

    </div>
  );
}

export default App;
