import React from 'react'
import "./Footer.scss"
const Footer = () => {
  return (
      <section>
<div className="subscribe">
<iframe class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://9sql.mjt.lu/wgt/9sql/hl8/form?c=3af6e8e1" margin="auto" width="450px" height="260px"></iframe>
</div>
      
    <footer>
    <div class="footerLogo">
    <a href="/">
            <img
                src="/logo_neg_transparentz-small.png"
                alt="boidverse"
                className="logo"
            />
        </a>
    </div>
    <div class="footerAbout">
        <h4>About</h4>
        <a href="/universe">The BoidVerse</a>
        <a href="/boidvoid">Boid Void</a>
        <a href="/boidcolony">Boid Colony</a>
    </div>
    <div class="footerSocials">
        <h4>Socials</h4>
        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/boid-com">LinkedIn</a>
        <a target="_blank" rel="noreferrer" href="https://t.me/boidcommunity">Telegram</a>
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/boidcom">Facebook</a>
        <a target="_blank" rel="noreferrer" href="https://discord.gg/92UZ4s6Y48">Discord</a>
        <a target="_blank" rel="noreferrer" href="https://twitter.com/boidcom">Twitter</a>
    </div>
    <div class="footerOthers">
        <h4>Others</h4>
        <a target="_blank" rel="noreferrer" href="https://pro.defibox.io/pool-market-details/8">Official Token</a>
    </div>
</footer>
</section>
  )
}

export default Footer