import React from 'react'
import Navbar from '../Navbar';
import "./Blog.scss"


const Blog = () => {
  
  return (
<div className="blog signup">
    <Navbar />
    <div className="signup_widget">
      <iframe
        data-w-type="embedded"
        frameborder="0"
        scrolling="no"
        marginheight="0"
        marginwidth="0"
        src="https://9sql.mjt.lu/wgt/9sql/hl8/form?c=3af6e8e1"
        width="70%"
        title="signup"
      />
    </div> 
 </div>
  )
}

export default Blog
