import Navbar from '../Navbar'
import Footer from '../Footer'
import "./Boidvoid.scss"
import "./BoidColony.scss"
import { motion } from 'framer-motion'

const BoidColony = () => {

  return (
    <div className="boidcolony">
        <Navbar />

        <section className="boidcolony">
        <div className="boidvoid_button-container boidcolony_container">
           <motion.img initial={{scale: 0}} animate={{scale: [0,1.05,1]}} transition={{duration:1, delay:0.5}}  src={process.env.PUBLIC_URL + '/boidcolony_title.png'}></motion.img>
               <motion.a initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1, delay:1}} href="#getstarted" class="button">get started</motion.a>
            </div>
        </section>

        <div class="bg-sections" id="getstarted">
    <section id="colony">
        <div class="contenedorColony">
            <h2>The Boid Colony</h2>
            <p>Boid Colony is an idle kingdom building and economic simulation set in space where players can manage their colony, make trade relations, and send their ships out in space to find rare materials.
            </p>
            <p>The First step into the BoidVerse. Players will be able to form colonies with other players led by a team leader and vice leader. Each building is an NFT. Each building will provide unique boosts to resource production. 
            </p>
        </div>
    </section>
    
    <section id="ark">
        <div class="contenedorArk">
            <div class="arkText">
                <h2>The Ark</h2>
               <div class="contenedorArkText">
                    <p>The Ark is a central piece of the Boids. It's a significant piece to the Boids. The Ark is the Boids' ancestral home. 
                    </p>
                    <p>The idea that a civilization can abandon a dying planet, or in the Boids' case, be forced to flee from their world and set off amongst the stars to colonize start over. 
                    </p>
               </div>
            </div>
            <div class="arkImg">
                <img src={process.env.PUBLIC_URL + '/Ark_1.png'} alt="ark img"></img>
            </div>
        </div>
    </section>
    
    
    <section id="planets">
        <div class="contenedorPlanets">
            <div class="planetsImg">
                <img src={process.env.PUBLIC_URL + '/planet img.png'} alt="planet img"></img>
            </div>
            <div class="planetsText">
                <h2>Colony Planets</h2>
               <div class="contenedorPlanetsText">
                    <p>These are planets that players land their Boid Colonies on and start growing their civilization.
                    Multiple players can end up on the same planet. A max of X players can start colonizing one planet.     
                    </p>
                    <p>Players will be able to trade with other players' colonies within their planet and establish more extensive trade routes with other worlds by building a more advanced version of the trading hub. 
                    </p>
               </div>
            </div>
        </div>
    </section>
    
    <section id="crystal">
        <div class="contenedorCrystal">
            <div class="crystalText">
                <h2>Boid Crystal</h2>
               <div class="contenedorCrystalText">
                    <p>The super rare crystal allows the transmittal and storage of data while providing integral structural strength matched by no other material. The Boid Crystal is considered the holy grail of materials. The Boid Crystal almost feels alive in every application as it adapts to the task at hand. 
                    </p>
                    <p>The Boid Crystal is the rarest known element in the known universe. It has outstripped rare metals such as Gold and Platinum and is often what the Nol and Boid alike strive to collect. The Boid Crystal signifies power and wealth. 
                    </p>
                    <p>Players' goal is to use their economic strategy to get as much of the Boid crystal as possible. Boid crystals allow for rare upgrades and can be extracted to BOID tokens.
                    </p>
               </div>
            </div>
            <div class="crystalImg">
                <img src={process.env.PUBLIC_URL + '/crystal img.png'} alt="crystal img"></img>
            </div>
        </div>
    </section>
</div>

<Footer />
 </div>
   
  )
}

export default BoidColony