import React, { useState } from "react"
import "./Avatars.scss"
import Footer from '../Footer'


const Avatars = () => {

    const [isActive, setActive] = useState("false");

    const handleToggle = () => {
      setActive(!isActive);
    };


    const[color, setColor] = useState('');
    const [bgFlag, setbgFlag] = useState(false);

    const [open, setOpen] = useState(false);

    const getURL = window.location.pathname;

    if(!bgFlag){
        setColor(getURL === '/avatars' ? 'white' : '')
        setbgFlag(true)
    }


  return (
    <div>
        <header>
        <a class="logo" href="#">boidverse</a>
        <div className={isActive ? "navAvatar" : "open-nav"}>
            <svg class="close" onClick={handleToggle} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1871 0L9 7.18714L1.81286 0L0 1.81286L7.18714 9L0 16.1871L1.81286 18L9 10.8129L16.1871 18L18 16.1871L10.8129 9L18 1.81286L16.1871 0Z" fill="#fff"/>
            </svg>
            <ul>
                <li><a style={{color:color}} class="link1" onClick={handleToggle} href="#intro">introduction</a></li>
                <li><a style={{color:color}} class="link2" onClick={handleToggle} href="#ukraine">ukraine</a></li>
                <li><a style={{color:color}} class="link3" onClick={handleToggle} href="#work">how it works</a></li>
                <li><a style={{color:color}} class="link4" onClick={handleToggle} href="#limited">limited edition</a></li>
                <li><a style={{color:color}} class="link5" onClick={handleToggle} href="#artist">artist</a></li>    
            </ul>
        </div>
        
        <div class="social-links">
            <a href="https://www.linkedin.com/company/boid-com/about/?viewAsMember=true">
                <svg class="icon linkedin" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.22594e-07 1.50382C2.22594e-07 1.10498 0.158438 0.722481 0.440458 0.44046C0.722479 0.158439 1.10498 2.0048e-06 1.50382 2.0048e-06H16.4945C16.6922 -0.00032081 16.888 0.0383444 17.0707 0.113784C17.2533 0.189223 17.4194 0.299955 17.5592 0.439641C17.699 0.579326 17.81 0.745221 17.8856 0.927828C17.9612 1.11044 18.0001 1.30617 18 1.50382V16.4945C18.0002 16.6922 17.9614 16.888 17.8859 17.0707C17.8104 17.2534 17.6996 17.4194 17.5598 17.5593C17.4201 17.6991 17.2541 17.81 17.0715 17.8856C16.8888 17.9612 16.6931 18.0001 16.4954 18H1.50382C1.30627 18 1.11065 17.9611 0.928143 17.8855C0.745638 17.8098 0.579822 17.699 0.440169 17.5593C0.300516 17.4195 0.189762 17.2536 0.114237 17.0711C0.0387109 16.8886 -0.00010726 16.6929 2.22594e-07 16.4954V1.50382ZM7.12473 6.86291H9.56209V8.08691C9.91391 7.38327 10.8139 6.75 12.1664 6.75C14.7592 6.75 15.3736 8.15155 15.3736 10.7231V15.4865H12.7497V11.3089C12.7497 9.84437 12.3979 9.018 11.5045 9.018C10.2649 9.018 9.74945 9.909 9.74945 11.3089V15.4865H7.12473V6.86291ZM2.62473 15.3745H5.24945V6.75H2.62473V15.3736V15.3745ZM5.625 3.93709C5.62995 4.16182 5.58996 4.38528 5.50738 4.59435C5.4248 4.80342 5.30129 4.99389 5.1441 5.15457C4.98691 5.31526 4.79921 5.44293 4.59201 5.5301C4.38481 5.61726 4.16229 5.66216 3.9375 5.66216C3.71271 5.66216 3.49019 5.61726 3.28299 5.5301C3.07579 5.44293 2.88809 5.31526 2.7309 5.15457C2.57371 4.99389 2.4502 4.80342 2.36762 4.59435C2.28504 4.38528 2.24505 4.16182 2.25 3.93709C2.25971 3.49597 2.44177 3.07619 2.75718 2.76765C3.07259 2.45911 3.49627 2.28634 3.9375 2.28634C4.37873 2.28634 4.80241 2.45911 5.11782 2.76765C5.43323 3.07619 5.61529 3.49597 5.625 3.93709Z" fill="white"/>
                </svg>
            </a>
            <a href="https://t.me/https://t.me/boidcommunity">
                <svg class="icon telegram" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0C4.4775 0 0 4.4775 0 10C0 15.5225 4.4775 20 10 20C15.5225 20 20 15.5225 20 10C20 4.4775 15.5225 0 10 0ZM14.9117 6.85083L13.27 14.5842C13.1492 15.1325 12.8225 15.2658 12.3667 15.0075L9.86667 13.1658L8.66167 14.3275C8.60262 14.4039 8.52686 14.4658 8.44019 14.5084C8.35352 14.5511 8.25825 14.5733 8.16167 14.5733H8.1575L8.335 12.0283L12.9683 7.84333C13.1683 7.66583 12.9233 7.565 12.6575 7.7425L6.93333 11.3475L4.46667 10.5775C3.93333 10.4083 3.91833 10.0442 4.57917 9.7825L14.2175 6.0675C14.6658 5.90417 15.0558 6.17417 14.9108 6.85167L14.9117 6.85083Z" fill="white"/>
                </svg>   
            </a>    
            <a href="https://www.facebook.com/boidcom">
                <svg class="icon facebook" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.101 0.9 18 2 18H9V11H7V8.525H9V6.475C9 4.311 10.212 2.791 12.766 2.791L14.569 2.793V5.398H13.372C12.378 5.398 12 6.144 12 6.836V8.526H14.568L14 11H12V18H16C17.1 18 18 17.101 18 16V2C18 0.9 17.1 0 16 0Z" fill="white"/>
                </svg>
            </a>    
            <a href="https://discord.gg/92UZ4s6Y48">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-discord" viewBox="0 0 16 16"> <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/></svg>
            </a>    
            <a href="https://twitter.com/boidcom">
                <svg class="icon twitter" width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.6425 2.93757C22.8075 3.30757 21.9105 3.55757 20.9675 3.67057C21.9405 3.08836 22.6685 2.17203 23.0155 1.09257C22.1014 1.63555 21.1009 2.01776 20.0575 2.22257C19.3559 1.47343 18.4266 0.976891 17.4138 0.810039C16.4011 0.643187 15.3616 0.815357 14.4568 1.29982C13.5519 1.78428 12.8323 2.55393 12.4097 3.48927C11.9871 4.42461 11.8851 5.47332 12.1195 6.47257C10.2672 6.37956 8.45515 5.89811 6.80095 5.05947C5.14674 4.22083 3.68736 3.04373 2.51752 1.60457C2.11752 2.29457 1.88752 3.09457 1.88752 3.94657C1.88708 4.71356 2.07596 5.46881 2.4374 6.1453C2.79885 6.82179 3.32168 7.3986 3.95952 7.82457C3.2198 7.80103 2.4964 7.60115 1.84952 7.24157V7.30156C1.84945 8.37731 2.22156 9.41994 2.90271 10.2526C3.58386 11.0852 4.53209 11.6565 5.58652 11.8696C4.90031 12.0553 4.18086 12.0826 3.48252 11.9496C3.78002 12.8752 4.35952 13.6846 5.13989 14.2645C5.92026 14.8444 6.86244 15.1657 7.83452 15.1836C6.18436 16.479 4.14641 17.1817 2.04852 17.1786C1.6769 17.1787 1.3056 17.157 0.936523 17.1136C3.066 18.4827 5.54486 19.2094 8.07652 19.2066C16.6465 19.2066 21.3315 12.1086 21.3315 5.95257C21.3315 5.75257 21.3265 5.55056 21.3175 5.35056C22.2288 4.69154 23.0154 3.87546 23.6405 2.94057L23.6425 2.93757Z" fill="white"/>
                </svg>
            </a>
                                      
        </div>
        
        <svg className="menuAvatar" onClick={handleToggle} width="29" height="24" viewBox="0 0 25 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H25V3.16667H0V0ZM6.25 7.91667H25V11.0833H6.25V7.91667ZM14.0625 15.8333H25V19H14.0625V15.8333Z" fill="#fff"/>
        </svg>
    </header>
    <div className="sections">
    <main>
        <div class="main-text">
            <h1>Boid Avatars!</h1>
            <p>Boid Avatars are a central part of the BoidVerse and provide many unique benefits on top of being a digital collectible!</p>
            <div class="main-button">
                <a href="#intro">Learn more</a>
            </div>
        </div>
        <div class="main-img">
            <img src={process.env.PUBLIC_URL + '/avatar-main.png'} alt="main avatar"></img>
        </div>
    </main>

    <section id="intro">
        <div class="intro-text">
            <h3>Introduction</h3>
            <h2>Introducing Boid Avatars!</h2>
            <p>This launch event represents the first step into the BoidVerse and as such, we wanted to share it with the eosio community. We recognize that without the community and figureheads that continue to lead the eosio community we wouldn't be where we are today. We proudly present The special edition Boid Avatars! <br></br><br></br>

            These Avatars were airdropped to some of the prominent figures who have made an impact and continue to support the eosio community to this day and have helped push the Boid Project to new heights.</p>
        </div>
        <div class="intro-img">
            <img src={process.env.PUBLIC_URL + '/avatar-intro.png'} alt="intro avatar"></img>
        </div>
    </section>


    <section id="ukraine">
        <div class="flex-ukraine">
            <div class="ukraine-text">
                <div class="subtitle">
                    <img src={process.env.PUBLIC_URL + '/ukraine-flag.png'} alt="ukraine-flag"></img>
                    <h3>Special Edition!</h3>
                </div>
                <h2>Ukraine Avatar</h2>
                <p>As a part of our launch, we are including a special edition "Ukraine" Avatar. The current conflict there hits close to home for many and we desire to help the Ukrainian people during these incredibly difficult times. That is why all profit sales from our special "Ukraine." avatar will go to a charity <a style={{color:"yellow"}} target="_blank" href="https://savelife.in.ua/en/donate-en/">https://savelife.in.ua/en/donate-en/</a> that helps the people of Ukraine directly.</p>
                <div class="main-button">
                <a href="https://eos.atomichub.io/market?collection_name=avatar.boid&order=desc&sort=created&symbol=EOS#auctions" target="_blank">See live auctions</a>
            </div>
            </div>
            <div class="ukraine-card">
                <img src={process.env.PUBLIC_URL + '/ukraine-card.png'} alt="ukraine-card"></img>
            </div>
        </div>
    </section>
<div className="sections2">
    <section id="work" >
        <div class="work-container">
            <div class="work-text">
                <h3>Avatars NFTs</h3>
                <h2>How does it work?</h2>
                <ul>
                    <li>Pack NFTs can be opened to reveal Avatar Part NFTs</li>
                    <li>Unique Avatar Templates can be designed by combining Avatar Part NFTs</li>
                    <li>Anyone can mint from Avatar Templates. Pricing and rarity is adetermined by the rarity rating of the parts used in the template.</li>
                    <li>50% of profits from avatar minting goes to the a count who designed the template.</li>
                    <li>Boid Avatars act as unique pass to participate in future BoidVerse ecosystem.</li>
                </ul>
                <div class="main-button">
                    <a target="_blank" href="https://avatar.boid.com">Enter Creation Tool</a>
                </div>
            </div>
            <div class="work-img">
                <img src={process.env.PUBLIC_URL + '/work-img-mobile.png'} alt="work-img"></img>
            </div>
            <div class="work-img2">
                <img src={process.env.PUBLIC_URL + '/work-img.png'} alt="work-img2"></img>
            </div>
        </div>
    </section>

    <section id="parts">
        <div class="parts-container">
            <div class="parts-text">
                <h3>Avatars NFTs</h3>
                <h2>The Card Parts</h2>
                <p>There are over 650 unique part cards that can be combined to create infinite variations. Parts can be purchased in tiered “packs” that include a random assortment of parts from all rarity levels. Higher tiers correspond to a higher likelihood of rare parts. 
                </p>
                <li>Pack prices:</li>
                        <li>Small    50k BOID</li>
                        <li>Medium    95k BOID</li>
                        <li>Large    135k BOID</li>
                        <li>Rare    200k BOID</li>
                        <li>Ultra Rare    250k BOID</li>

                        <p>We burn 100% BOID tokens from Avatar Maker sales.</p>
            </div>
            <div class="parts-img">
                <img src={process.env.PUBLIC_URL + '/card-img-mobile.png'} alt="parts-img"></img>
            </div>
            <div class="parts-img2">
                <img src={process.env.PUBLIC_URL + '/card-img.png'} alt="parts-img2"></img>
            </div>
        </div>
    </section>

    <section id="limited">
        <div class="limited-text">
            <h2>Limited Edition</h2>
            <p>Boid Avatars are a central part of the BoidVerse and provide many unique benefits on top of being a digital collectible!</p>
            
        </div>
        <div class="limited-container">
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar1.png'} alt="avatar1"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar2.png'} alt="avatar2"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar3.png'} alt="avatar3"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar4.png'} alt="avatar4"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar5.png'} alt="avatar5"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar6.png'} alt="avatar6"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar7.png'} alt="avatar7"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar8.png'} alt="avatar8"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar9.png'} alt="avatar9"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar10.png'} alt="avatar10"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar11.png'} alt="avatar11"></img>
            </div>
            <div class="limited-item">
                <img src={process.env.PUBLIC_URL + '/avatar12.png'} alt="avatar12"></img>
            </div>
        </div>
    </section>

    <section id="artist">
        <div class="intro-text">
            <h3>Oksana Mykytiuk</h3>
            <h2>Meet The Artist</h2>
            <p>Oksana Mykytiuk is a professional game artist with 6 years of experience in the game industry. Previously she has lent her skills to arcades, city builders, slot machines, tower defense games, card games and applications for kids. Oksana uses Photoshop and Illustrator to create outstanding game design. She is a great artist and easy to work with. You can find her on:<br></br><br></br>

           <div class="links">
            <a href="https://www.upwork.com/freelancers/~0147b9a33c1f25755e?s=1017484851352698968">Upwork<br></br>
            <a href="https://www.behance.net/barmaslonik/projects">Behance</a>
            </a>
           </div>
           </p>
        </div>
        <div className="image">
            <img src={process.env.PUBLIC_URL + '/oksana.png'}></img>
        </div>
    </section>
  
    <section id="blank">
    <div class="margin-bottom: 250px">aa</div>
    </section>

    {/* <section id="airdrop">
        <div class="intro-text">
            <h3>Airdrop</h3>
            <h2>Join The Airdrop</h2>
            <p>Users holding existing Boid NFTs are automatically included in the airdrop.
            </p>
            <div class="drops">
            <div class="drops-item">
                <a target="_blank" href="https://eos.atomichub.io/explorer/template/boid4science/1420"><img src={process.env.PUBLIC_URL + '/drop_silver.png'} alt="avatar1"></img></a>
            </div>
            <div class="drops-item">
                <a target="_blank" href="https://eos.atomichub.io/explorer/template/boid4science/1419"><img src={process.env.PUBLIC_URL + '/drop_gold.png'} alt="avatar2"></img></a>
            </div>
            <div class="drops-item">
                <a target="_blank" href="https://eos.atomichub.io/explorer/template/boid4science/49"><img src={process.env.PUBLIC_URL + '/drop_nikogold.png'} alt="avatar3"></img></a>
            </div>
            <div class="drops-item">
                <a target="_blank" href="https://eos.atomichub.io/explorer/template/boid4science/50"><img src={process.env.PUBLIC_URL + '/drop_nikosilver.png'} alt="avatar4"></img></a>
            </div>
            <div class="drops-item">
                <a target="_blank" href="https://eos.atomichub.io/explorer/template/boid4science/52"><img src={process.env.PUBLIC_URL + '/drop_nikobrass.png'} alt="avatar5"></img></a>
            </div>
            <div class="drops-item">
                <a target="_blank" href="https://eos.atomichub.io/explorer/template/boid4science/51"><img src={process.env.PUBLIC_URL + '/drop_nikobronze.png'} alt="avatar6"></img></a>
            </div>
        </div>
            
        </div>
        
    </section> */}
    </div>
    </div>
    <Footer />
    </div>
  )
}

export default Avatars