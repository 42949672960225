
import Header from "../Header"
import Whatis from '../Whatis';
import TheGame from '../TheGame';
import WhitepaperSection from "../WhitepaperSection"
import Footer from '../Footer';
import Navbar from '../Navbar';

function App() {
  return (
    <div className="black-bars">
            <Navbar />
            <Header />
            <Whatis />
            <TheGame />
            <WhitepaperSection />
            <Footer />
    </div>
  )
}

export default App;
