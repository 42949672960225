import React from 'react'
import "./Whatis.scss"
import { motion } from "framer-motion"

const Whatis = () => {
  return (
    <section id="getstarted" className="whatis">
      <div className="whatis__content-container">
        <div className="whatis__content-left">
            <motion.h2 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{duration:1, delay:.3}} className="whatis__content-h2">What is BoidVerse?</motion.h2>
              <div className="whatis__content-p-container">
                 <motion.p initial={{ opacity: 0 }} animate={{ opacity: .7 }} transition={{duration:1, delay:.5}} className="whatis__content-p">
                     The BoidVerse is a radical new take on The Play-to-Earn model driving players
                     incentivizing them to put forth their spare computing power helping to solve
                     some of the worlds greatest diseases, including Cancer, while being rewarded
                     greatly for their time in game.To learn more about the heart of the BoidVerse
                     check out the dedicated site for the Boid project. <br></br> <br></br>
                 </motion.p>
                  <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{duration:1, delay:.5}} className="button"><a href="/universe">Learn More</a></motion.button>
             </div>
          
      </div>
        <div className="whatis__content-right">
        <motion.img animate={{y:[0,50,0]}} transition={{duration:5, repeat: Infinity}} src={process.env.PUBLIC_URL + '/boiduniverse.png'}></motion.img>
        </div>
      </div>
    </section>
  )
}

export default Whatis