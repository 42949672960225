import React from 'react'
import "./TheGame.scss"

const TheGame = () => {
  return (
    <div>
      <section id="theGame">
    <div className="theGame">
        <p className="about">ABOUT</p>
        <h2>The Metaverse</h2>
        <p>The games of the BoidVerse build upon one another and allow players to explore an in-depth economy that allow people to buy, sell, trade, and grow their colony and explore the stars in an effort to extract the most coveted treasure of all the Boid Crystal. Every Boid Crystal can be used to make rare NFT buildings in game or converted to the BOID token and sent to the player's EOS wallet.
        </p>
        <div className="btnGame">
            <a href="/boidvoid" class="btn">boid void</a>
            <a href="/boidcolony" class="btn btn-right">boid colony</a>
        </div>

    </div>
    <div className="cards">
        <div className="card card1">
            <img src={process.env.PUBLIC_URL + '/game1.png'} alt="PlayToEarnImg"></img>
            <h3>Play-to-earn</h3>
            <p>The Play-to-Earn mechanism provides players with a rewarding and  potentially lucrative experience.
            </p>
        </div>
        <div className="card card2">
            <img src={process.env.PUBLIC_URL + '/game2.png'} alt="PlayersAndEcononyImg"></img>
            <h3>Players and Economy</h3>
            <p>The more players, the more diverse and robust the economic market will become. Long time players will contribute significantly to the World Community Grid via their spare computing power.
            </p>
        </div>
        <div className="card card3">
            <img src={process.env.PUBLIC_URL + '/game3.png'} alt="BoidTokenImg"></img>
            <h3>Boid token</h3>
            <p>The Boid Token has a robust utility through staking for various in game boosts, purchasable NFTs, and multiple deflationary facets that will burn the BOID token causing the circulating supply to become reduced.
            </p>
        </div>
    </div>
</section>
    </div>
  )
}

export default TheGame