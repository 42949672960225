import React from 'react'
import useSWR from 'swr'
import Cosmic from 'cosmicjs'
import Navbar from '../Navbar';
import Footer from "../Footer"
import "./Blog.scss"
const api = Cosmic()
// Set these values, found in Bucket > Settings after logging in at https://app.cosmicjs.com/login
const bucket = api.bucket({
  slug: '9db8be90-b426-11ec-a7e8-d31498be7338',
  read_key: 'hKVpH03exRKYnzds7vEF6UCwl0PkgLf8IyZuGFTuOvobW5t3TK'
})
const fetchPosts = async () => {
  const data = await bucket.getObjects({
    query: {
      type: 'posts'
    },
    props: 'slug,title,metadata,content,published_at,modified_by,bucket' // Limit the API response data by props
  })
  return data
}

const Blog = () => {
  
  const { data, error } = useSWR('fetch-posts', fetchPosts) // Use SWR hook
  if (!data) return <div>Loading...</div>
  const posts = data.objects
  
  return (
<div className="blog">
    <Navbar />
        <div className="posts_container">
              {posts.map((post, index) => (
            <div className="post_card" key={post.slug} style={{ marginBottom: 20 }}>
                  
                  <div className="container_date">
                    <div className="post_date">{post.published_at.slice(0,-14)}</div>
                    <div className="post_time">{post.published_at.slice(11,-8)}</div>
                  </div>

                  <div className="post_title">{post.title}</div>
                  <div className="post_description">{post.metadata.subtitle}</div>
                  <div className="post_image"><img alt="hero" src={`${post.metadata.image.imgix_url}`}/></div>
                  <div dangerouslySetInnerHTML={{__html: post.metadata.content}} />
            </div>
              ))}
        </div>
        <Footer />
</div>
  )
}

export default Blog