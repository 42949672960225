import { useState } from 'react';
import Navbar from '../Navbar'
import Footer from '../Footer'
import "./Boidvoid.scss"
import { motion } from 'framer-motion'

const Boidvoid = () => {

    const [info, setInfo] = useState(0);

  return (
    <div className="boidvoid black-bars">
        <Navbar />

        <section className="boidvoid_header">
           <div className="boidvoid_button-container">
           <motion.img initial={{scale: 0}} animate={{scale: [0,1.05,1]}} transition={{duration:1, delay:0.5}}  src={process.env.PUBLIC_URL + '/boidvoid_title.png'}></motion.img>
               <motion.a initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1, delay:1}} href="#getstarted" class="button">get started</motion.a>
            </div>

          <div className="boidvoid_info-container">
            <div className="boidvoid_info-container-left">
              <h1 id="getstarted" className="whatis__content-h2">The Boid Void</h1>
                  <div className="whatis__content-p-container border">
                      <p className="whatis__content-p boidvoid_info-container-left-p">BoidVoid is a turn-based hex-field game where two or more teams compete 
                          to capture all tiles on a map. Each player controls their ship, destroying 
                          other teams ships and capturing other tiles. The game ends when its season ends.</p>
                  </div>
            </div>
            
          <div className="boidvoid_info-container-right">
          <motion.img animate={{y:[0,50,0]}} transition={{duration:5, repeat: Infinity}} src={process.env.PUBLIC_URL + '/boidbattery.png'}></motion.img>
          </div>

          </div>
        </section>

<section className="boidvoid_slider">
    <div className="boidvoid_slider-container">
        <div className="boidvoid_slider-container_left">
            <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet1.png'} alt="planet"></img><h2 style={{color: info === 0 ? 'white' : ''}} onClick={() => setInfo(0)}>Season and Games</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet2.png'} alt="planet"></img><h2 style={{color: info === 1 ? 'white' : ''}} onClick={() => setInfo(1)}>Team Composition</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet3.png'} alt="planet"></img><h2 style={{color: info === 2 ? 'white' : ''}} onClick={() => setInfo(2)}>Non-Playable Characters</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet4.png'} alt="planet"></img><h2 style={{color: info === 3 ? 'white' : ''}} onClick={() => setInfo(3)}>Point of Importance</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet5.png'} alt="planet"></img><h2 style={{color: info === 4 ? 'white' : ''}} onClick={() => setInfo(4)}>Tile</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet6.png'} alt="planet"></img><h2 style={{color: info === 5 ? 'white' : ''}} onClick={() => setInfo(5)}>Obstacles</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet7.png'} alt="planet"></img><h2 style={{color: info === 6 ? 'white' : ''}} onClick={() => setInfo(6)}>Automode</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet8.png'} alt="planet"></img><h2 style={{color: info === 7 ? 'white' : ''}} onClick={() => setInfo(7)}>Ships</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet9.png'} alt="planet"></img><h2 style={{color: info === 8 ? 'white' : ''}} onClick={() => setInfo(8)}>Action Points</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet10.png'} alt="planet"></img><h2 style={{color: info === 9 ? 'white' : ''}} onClick={() => setInfo(9)}>Captain and Vice-Captain</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet11.png'} alt="planet"></img><h2 style={{color: info === 10 ? 'white' : ''}} onClick={() => setInfo(10)}>Turn</h2></div>
             <div className="flex-title"><img src={process.env.PUBLIC_URL + '/planet12.png'} alt="planet"></img><h2 style={{color: info === 11 ? 'white' : ''}} onClick={() => setInfo(11)}>Boid Battery</h2></div>
        </div>
        <div className="boidvoid_slider-container_right">
            <div style={{display: info === 0 ? 'block' : 'none'}}>
                <h1>Seasons and Games</h1>
                <p>The season occurs between two specific dates. No two seasons can run at the same time, and each season can have as many games as needed.</p>
            </div>

            <div style={{display: info === 1 ? 'block' : 'none'}}>
                <h1>Team Composition</h1>
                <p>Theres no limit to the number of players per team, but each team should have a team leader and a vice-leader. Any other player is considered a trooper. Each player controls a spaceship, and leaders and vice-leaders can control the teams mothership and swarms.</p>
            </div>


            <div style={{display: info === 2 ? 'block' : 'none'}}>
                    <h1>Non-Playable Characters</h1>
                    <p>Non-Playable Characters are units on the map that behave automatically. They can move and attack other units.</p>
            </div>

            <div style={{display: info === 3 ? 'block' : 'none'}}>
                    <h1>Point of Importance</h1>
                    <p>Points of importance can be captured by players to provide additional bonuses to the players on it.</p>
            </div>
            <div style={{display: info === 4 ? 'block' : 'none'}}>
                    <h1>Tile</h1>
                    <p>A map is composed by several tiles on a 2D disposition. Its shaped as a hexagon and contains information about bonuses and other map related elements such as ships, points of importance, captures. Both capture and movement cost are the most basic information a tile can have.</p> 
            </div>

            <div style={{display: info === 5 ? 'block' : 'none'}}>
                    <h1>Obstacles</h1>
                    <p>Obstacles are allocated on tiles so that players cannot move to those tiles. </p>
            </div>

            <div style={{display: info === 6 ? 'block' : 'none'}}>
                    <h1>Automode</h1>
                    <p>Since most players are not expected to participate 24/7, they can activate automode. Ships on automode move to the closest swarm. If no swarm is available, the ship creates a new swarm.</p>
            </div>   

            <div style={{display: info === 7 ? 'block' : 'none'}}>
                    <h1>Ships</h1>
                    <p>Spaceships are controlled by players. Each spaceship has different characteristics based on the NFT template used. Currently, only one ship template is available. Spaceships can move around, attack other ships, capture tiles and build superstructures. <br></br><br></br>

                    Motherships are controlled by the team leader and the vice-leader. They are required to capture tiles. These ships cannot attack other ships, build superstructures or capture tiles. <br></br><br></br>
                        
                    Swarms are controlled by the team leader and the vice-leader. They can attack other ships but cannot build superstructures or capture tiles. <br></br>
                    Spaceships can be either actively controlled by a player or set to automode. Automode ships move automatically to the closest swarm. If theres no swarm available, the ship starts a new swarm.
                    </p>
            </div>

            <div style={{display: info === 8 ? 'block' : 'none'}}>
                    <h1>Action Points</h1>
                    <p>Action points are used to move through the map. The action points are recharged once per turn based on the spaceships status, and bonuses from the tile, a point of importance and a superstructure.</p>
            </div>
            
            <div style={{display: info === 9 ? 'block' : 'none'}}>
                    <h1>Captain and Vice-Captain</h1>
                    <p>Captains and vice captains can move swarms and motherships around and perform actions. Theres no functional difference between one another.</p>
            </div>
            
            <div style={{display: info === 10 ? 'block' : 'none'}}>
                    <h1>Turn</h1>
                    <p>The turn is the main unit of time used for players to interact with the game. The turn is set on the jobs dashboard appsettings.</p>
            </div>

            <div style={{display: info === 11 ? 'block' : 'none'}}>
                    <h1>Boid Battery</h1>
                    <p>The battery is associated to the amount of powered staked BOID the user has. Each turn, the battery is recharged by a certain amount set on the game. During the daily maintenance, the maximum Boid battery is updated.</p>
                    <img src={process.env.PUBLIC_URL + '/NFTimg.png'} alt="planet"></img>
            </div>   


        </div>
        
    </div>


</section>












{/* 
        <section id="boidVoid">
    <div class="contenedorBoid">
        <div class="colBoid">
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet1.png'} alt="planeta1"></img>
                </div>
                <div class="textBoid">
                    <h2>Season and games</h2>
                    <p>The season occurs between two specific dates. No two seasons can run at the same time, and each season can have as many games as needed.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet2.png'} alt="planeta2"></img>
                </div>
                <div class="textBoid">
                    <h2>Team composition</h2>
                    <p>Theres no limit to the number of players per team, but each team should have a team leader and a vice-leader. Any other player is considered a trooper. Each player controls a spaceship, and leaders and vice-leaders can control the teams mothership and swarms.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet3.png'} alt="planeta3"></img>
                </div>
                <div class="textBoid">
                    <h2>Non-Playable Characters</h2>
                    <p>Non-Playable Characters are units on the map that behave automatically. They can move and attack other units.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet4.png'} alt="planeta4"></img>
                </div>
                <div class="textBoid">
                    <h2>Point of Importance</h2>
                    <p>Points of importance can be captured by players to provide additional bonuses to the players on it.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet5.png'} alt="planeta5"></img>
                </div>
                <div class="textBoid">
                    <h2>Tile</h2>
                    <p>A map is composed by several tiles on a 2D disposition. Its shaped as a hexagon and contains information about bonuses and other map related elements such as ships, points of importance, captures. Both capture and movement cost are the most basic information a tile can have.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet6.png'} alt="planeta6"></img>
                </div>
                <div class="textBoid">
                    <h2>Obstacles</h2>
                    <p>Obstacles are allocated on tiles so that players cannot move to those tiles. </p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet7.png'} alt="planeta7"></img>
                </div>
                <div class="textBoid">
                    <h2>Automode</h2>
                    <p>Since most players are not expected to participate 24/7, they can activate automode. Ships on automode move to the closest swarm. If no swarm is available, the ship creates a new swarm.</p>
                </div>   
            </div>
        </div>
        <div class="colBoid">
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet8.png'} alt="planeta8"></img>
                </div>
                <div class="textBoid">
                    <h2>Ships</h2>
                    <p>Spaceships are controlled by players. Each spaceship has different characteristics based on the NFT template used. Currently, only one ship template is available. Spaceships can move around, attack other ships, capture tiles and build superstructures. <br></br><br></br>

                    Motherships are controlled by the team leader and the vice-leader. They are required to capture tiles. These ships cannot attack other ships, build superstructures or capture tiles. <br></br><br></br>
                        
                    Swarms are controlled by the team leader and the vice-leader. They can attack other ships but cannot build superstructures or capture tiles. <br></br>
                    Spaceships can be either actively controlled by a player or set to automode. Automode ships move automatically to the closest swarm. If theres no swarm available, the ship starts a new swarm.
                    </p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet9.png'} alt="planeta9"></img>
                </div>
                <div class="textBoid">
                    <h2>Action Points</h2>
                    <p>Action points are used to move through the map. The action points are recharged once per turn based on the spaceships status, and bonuses from the tile, a point of importance and a superstructure.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet10.png'} alt="planeta10"></img>
                </div>
                <div class="textBoid">
                    <h2>Captain and Vice-Captain</h2>
                    <p>Captains and vice captains can move swarms and motherships around and perform actions. Theres no functional difference between one another.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet11.png'} alt="planeta11"></img>
                </div>
                <div class="textBoid">
                    <h2>Turn</h2>
                    <p>The turn is the main unit of time used for players to interact with the game. The turn is set on the jobs dashboard appsettings.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="planet">
                    <img src={process.env.PUBLIC_URL + '/planet12.png'} alt="planeta12"></img>
                </div>
                <div class="textBoid">
                    <h2>Boid Battery</h2>
                    <p>The battery is associated to the amount of powered staked BOID the user has. Each turn, the battery is recharged by a certain amount set on the game. During the daily maintenance, the maximum Boid battery is updated.</p>
                </div>   
            </div>
        </div>
    </div>
</section> */}

<Footer />
 </div>
   
  )
}

export default Boidvoid