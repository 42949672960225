
import { useState } from 'react';
import "./Navbar.scss"
import MobileMenu from './MobileMenu';

const Navbar = () => {
   
    const[color, setColor] = useState('');
    const [bgFlag, setbgFlag] = useState(false);

    const [open, setOpen] = useState(false);

    const getURL = window.location.pathname;

    if(!bgFlag){
        setColor(getURL === '/boidvoid' || getURL === '/boidcolony' || getURL === '/blog' || getURL === '/signup' || getURL === '/universe' ? 'white' : '')
        setbgFlag(true)
    }

    const logoSrc = getURL === '/' ? '/logo_pos_nobg-small.png' : '/logo_neg_transparentz-small.png';
   
  return (
    <nav style={{background: getURL === '/boidvoid' || getURL === '/boidcolony' || getURL === '/blog' || getURL === '/signup' || getURL === '/universe' ? 'none' : '' }}>
    <div className="logo">
        <a href="/">
            <img
                src={logoSrc}
                alt="boidverse"
                className="logo"
            />
        </a>
    </div>

    <div className="navbar">
        <ul>
            <li><a style={{color:color}} href="/universe">the boidverse</a></li>
            <li><a style={{color:color}} href="/boidvoid">boid void</a></li>
            <li><a style={{color:color}} href="/boidcolony">boid colony</a></li>
            <li><a style={{color:color}} target="_blank" href="https://avatar.boid.com">Avatars</a></li>
            <li><a style={{color:color}} target="_blank" href="https://lore.boid.com">LORE</a></li>
            <li><a style={{color:color}} target="_blank" href="https://nft.boid.com">NFTs</a></li>
        </ul>
    </div>
    <div className="socialLinks">
        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/boid-com"> <svg width="24" height="24" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"> <path style={{fill:color}} fill-rule="evenodd" clip-rule="evenodd" d="M5.31753e-07 3.59246C5.31753e-07 2.63968 0.37849 1.72593 1.05221 1.05221C1.72592 0.378494 2.63968 4.78925e-06 3.59245 4.78925e-06H39.4036C39.8758 -0.00076638 40.3435 0.0916005 40.7799 0.271817C41.2163 0.452033 41.6129 0.71656 41.947 1.05025C42.281 1.38395 42.546 1.78025 42.7267 2.21648C42.9074 2.65271 43.0003 3.12029 43 3.59246V39.4036C43.0005 39.8759 42.9079 40.3436 42.7275 40.7801C42.547 41.2165 42.2823 41.6131 41.9485 41.9471C41.6146 42.2811 41.2182 42.5461 40.7819 42.7267C40.3455 42.9074 39.8778 43.0003 39.4056 43H3.59245C3.12052 43 2.65321 42.907 2.21723 42.7264C1.78125 42.5457 1.38513 42.2809 1.05151 41.9471C0.717899 41.6133 0.453321 41.217 0.272899 40.781C0.0924759 40.3449 -0.000256232 39.8775 5.31753e-07 39.4056V3.59246ZM17.0202 16.3947H22.8428V19.3187C23.6832 17.6378 25.8332 16.125 29.0641 16.125C35.258 16.125 36.7259 19.4731 36.7259 25.6163V36.9956H30.4577V27.0157C30.4577 23.5171 29.6172 21.543 27.4829 21.543C24.5217 21.543 23.2904 23.6715 23.2904 27.0157V36.9956H17.0202V16.3947ZM6.27018 36.7279H12.5404V16.125H6.27018V36.7259V36.7279ZM13.4375 9.40528C13.4493 9.94214 13.3538 10.476 13.1565 10.9754C12.9592 11.4748 12.6642 11.9298 12.2887 12.3137C11.9132 12.6976 11.4648 13.0026 10.9698 13.2108C10.4748 13.419 9.94324 13.5263 9.40625 13.5263C8.86926 13.5263 8.33768 13.419 7.8427 13.2108C7.34773 13.0026 6.89933 12.6976 6.52382 12.3137C6.14831 11.9298 5.85326 11.4748 5.65599 10.9754C5.45871 10.476 5.36318 9.94214 5.375 9.40528C5.3982 8.35149 5.83312 7.34869 6.58659 6.61162C7.34007 5.87455 8.35221 5.46182 9.40625 5.46182C10.4603 5.46182 11.4724 5.87455 12.2259 6.61162C12.9794 7.34869 13.4143 8.35149 13.4375 9.40528Z" fill=" #0E185F"/> </svg></a>
        <a target="_blank" rel="noreferrer" href="https://t.me/boidcommunity"><svg width="24" height="24" viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg"><path style={{fill:color}} d="M46.6251 0.282735L2.33469 17.7963C-0.687946 19.0413 -0.67046 20.7703 1.78013 21.5414L13.1512 25.1788L39.4607 8.15707C40.7047 7.38091 41.8413 7.79844 40.907 8.64889L19.5912 28.3757H19.5862L19.5912 28.3783L18.8068 40.3973C19.9559 40.3973 20.463 39.8568 21.1075 39.2189L26.6307 33.7115L38.1192 42.4132C40.2376 43.6095 41.7589 42.9947 42.286 40.4024L49.8276 3.95606C50.5995 0.782246 48.646 -0.654807 46.6251 0.282735Z" fill=" #0E185F"/></svg></a> 
        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/boidcom"><svg width="24" height="24" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"><path style={{fill:color}} d="M38.2222 0H4.77778C2.15 0 0 2.15 0 4.77778V38.2222C0 40.8524 2.15 43 4.77778 43H21.5V26.2778H16.7222V20.3653H21.5V15.4681C21.5 10.2985 24.3953 6.66739 30.4966 6.66739L34.8037 6.67217V12.8952H31.9442C29.5697 12.8952 28.6667 14.6773 28.6667 16.3304V20.3677H34.8013L33.4444 26.2778H28.6667V43H38.2222C40.85 43 43 40.8524 43 38.2222V4.77778C43 2.15 40.85 0 38.2222 0Z" fill=" #0E185F"/></svg></a>
        <a target="_blank" rel="noreferrer" href="https://discord.gg/92UZ4s6Y48"><svg style={{fill:color}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill=" #0E185F" class="bi bi-discord" viewBox="0 0 16 16"> <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/></svg></a>
        <a target="_blank" rel="noreferrer" href="https://twitter.com/boidcom"><svg width="24" height="24" viewBox="0 0 54 44" fill="none" xmlns="http://www.w3.org/2000/svg"><path style={{fill:color}} d="M54 5.2192C52.0142 6.10118 49.8809 6.69711 47.6382 6.96647C49.9523 5.57865 51.6835 3.39438 52.5089 0.821236C50.3348 2.11557 47.9554 3.02664 45.4741 3.51484C43.8055 1.72911 41.5953 0.545502 39.1868 0.147773C36.7783 -0.249957 34.3061 0.160448 32.1542 1.31527C30.0022 2.47009 28.2908 4.30472 27.2858 6.53431C26.2807 8.76391 26.0381 11.2637 26.5957 13.6457C22.1905 13.424 17.881 12.2763 13.9469 10.2772C10.0129 8.27814 6.54212 5.47226 3.75997 2.0417C2.80868 3.68647 2.26169 5.59345 2.26169 7.62438C2.26063 9.45268 2.70983 11.253 3.56943 12.8655C4.42903 14.4781 5.67245 15.8531 7.18938 16.8685C5.43015 16.8123 3.70973 16.3359 2.17132 15.4787V15.6218C2.17114 18.186 3.0561 20.6714 4.67603 22.6561C6.29596 24.6409 8.55108 26.0027 11.0588 26.5106C9.42677 26.9533 7.71577 27.0185 6.05496 26.7013C6.76248 28.9077 8.14066 30.8371 9.99656 32.2194C11.8525 33.6018 14.0932 34.3678 16.405 34.4103C12.4805 37.4982 7.63384 39.1732 2.64459 39.1658C1.76079 39.166 0.877747 39.1143 0 39.0109C5.06438 42.2746 10.9597 44.0067 16.9805 44C37.3619 44 48.5039 27.0803 48.5039 12.4061C48.5039 11.9294 48.492 11.4479 48.4706 10.9711C50.6379 9.40019 52.5086 7.45489 53.9952 5.22636L54 5.2192Z" fill=" #0E185F"/></svg></a>

    </div>

    <div className="hamburger" onClick={() => setOpen(true)} style={{display: open === true || window.innerWidth > 1000  ? "none" : "block"}}>
        <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11.2551H21M1 1H21H1ZM1 6.12754H21H1Z" style= {{stroke: getURL === '/boidvoid' || getURL === '/boidcolony' || getURL === '/universe' || getURL === '/blog' || getURL === '/signup' ? 'white' : '#0E185F'}} stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>

    <div className="cross" onClick={() => setOpen(false)} style={{display: open === false ? "none" : "block"}}>
        <svg width="32" height="32" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3981 5.86719L20.1334 6.60247L6.60198 20.1329L5.8667 19.3981L19.3981 5.86719Z" fill="white"/>
            <path d="M6.60198 5.86719L20.1334 19.3976L19.3981 20.1334L5.8667 6.60299L6.60198 5.86719Z" fill="white"/>
        </svg>
    </div>

    <div className="menu" style={{display: open === false ? "none" : "block", position: open === false ? "" : "fixed"}}>
        <MobileMenu />
    </div>

</nav>
  )
}

export default Navbar