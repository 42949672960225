import React from 'react'
import "./Header.scss"
import { motion } from "framer-motion"

const Header = () => {
  return (
    <section className="header">

{/* <div className="refere"> <img src="HOMEMOBILE.png"></img> </div>  */}

       <div class="refe">
        <div class="fixed">
        <div class="banner">
            <div class="heroText">
                <motion.h1 initial={{ opacity:0}} animate={{y:0,opacity:1}} transition={{duration:1, delay:.5}}>The metaverse that makes a difference</motion.h1>
                <motion.p initial={{ opacity:0}} animate={{y:0,opacity:1}} transition={{duration:1, delay:.7}}>The Communities of Today Powering the World of Tomorrow.</motion.p>
                <motion.p initial={{ opacity:0}} animate={{y:0,opacity:1}} transition={{duration:1, delay:.8}}>Experience a Play to Earn Like never Before.</motion.p>
                <motion.div initial={{ opacity:0}} animate={{y:0,opacity:1}} transition={{duration:1, delay:1}} class="botonesHero">
                    <a href="#getstarted" class="btn btn1">get started</a>
                    <a href="/universe" class="btn btn2">learn more</a>
                </motion.div>
            </div>
        </div>
    </div>
    </div>
    </section>
  )
}

export default Header