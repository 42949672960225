import React from 'react'
import "./WhitepaperSection.scss"

const WhitepaperSection = () => {
  return (
    <section className="nolarchives">
      <div className="nolarchives_container">
        <div className="nolarchives_right">
            <h2 className="whatis__content-h2">Nol Archives</h2>
              <div className="whatis__content-p-container">
                 <p className="whatis__content-p">
                     The BoidVerse itself is a collection of interconnected games that allow players 
                     to explore the universe of Boid through a deep Lore that is explored and expanded
                     upon by uncovering unique NFTs. Visit our lore page to learn more about the Lore
                     of the BoidVerse.
                 </p>
                 <button className="button buttonuniverse"><a target="_blank" href="https://lore.boid.com">Learn The Lore</a></button>
                 </div>
             </div>

      </div>

    </section>
  )
}

export default WhitepaperSection