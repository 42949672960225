import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import "./Boidvoid.scss"
import "./TheUniverse.scss"
import 'swiper/css';

const TheUniverse = () => {

  return (
     
    <div className="black-bars">
         <Navbar />
         <section className="universe-title">
            <h1>The Boidverse</h1>
         </section>


         <section className="universe" id="boidVoid">
    <div class="contenedorBoid buildings_container">
        <div class="colBoid colUniv">
            <div class="rowBoid">
                 <div class="textBoid">
                    <h1 className="titleBoid">Buildings</h1>
                    <p>The First step into the Boidverse. Players will be able to form colonies with other players led by a team leader and a vice team leader. Each player will have their own plot as well as a colony plot to begin developing. Every building players build is an NFT. Each building will provide unique boosts to resource production. 
                            <li>NFT Buildings</li>
                            <li>Upgradable rarity for buildings</li>
                            <li>Internal Market/Exchange</li>
                            <li>Mining and extracting of Boid Crystals to BOID tokens</li>
                      </p>
                </div>  
            </div> 
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Shipyards</h2>
                    <p>For building trade routes. Players sacrifice a certain amount of multiple resource types to get a delivery of Boid Crystals.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Foundry</h2>
                    <p>Players turn minerals into Steel, aluminium or titanium. Users pick what they want and the varying amount of minerals is taken from them over the course of time that they are making the alloys. Multiple Foundries on the map will allow them to convert into multiple times. Higher rarity Foundries have multiple slots</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Research Station</h2>
                    <p>Water + Chemicals are used to make research points which can be used to discover new building types, upgrades, and blueprints that speed up construction and throughput</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Market</h2>
                    <p>Food + Water are sold for additional Gold. Gold is the currency that is used in the BoidVerse and is used in all transactions.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Mercenary Hubs</h2>
                    <p>Players pay Gold to hire mercenaries to steal Boid Crystals. Has a varying percentage chance of winning big or getting very little.</p>
                </div>   
            </div>
        </div>
        <div class="colBoid colUniv">
        <div class="rowBoid">
                <div class="textBoid">
                  <img src={process.env.PUBLIC_URL + '/universe.png'} alt="planet"></img>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Power Generator</h2>
                    <p>Holds Boid Power. </p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Boid Batteries</h2>
                    <p>NFTs that can be charged by players who have excess Boid Power and sold to players who would like additional Boid Power.</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>The Center</h2>
                    <p>A Branch of a massive supercomputer that facilitates the Bond with the Boid</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Council</h2>
                    <p>Where colony members can vote on how to progress the colony at large</p>
                </div>   
            </div>
            <div class="rowBoid">
                <div class="textBoid">
                    <h2>Furnance</h2>
                    <p>Users can burn Boid Crystals and extract the BOID contained within.</p>
                </div>   
            </div>
        </div>
    </div>
</section>
        

<section className="mineables">

  <h1 className='titleBoid'>Mineables and Collectables</h1>


<div className="minerals-container">

      <div className="slide-item minerals">
        <img src={process.env.PUBLIC_URL + '/minerals_1.png'} alt="planet"></img>
        <h2>Minerals</h2>
      </div>

      <div className="slide-item titanium">
        <img src={process.env.PUBLIC_URL + '/minerals_2.png'} alt="planet"></img>
        <h2>Titanium</h2>
      </div>

      <div className="slide-item gold">
        <img src={process.env.PUBLIC_URL + '/minerals_3.png'} alt="planet"></img>
        <h2>Gold</h2>
      </div>

      <div className="slide-item boidcrystal">
        <img src={process.env.PUBLIC_URL + '/minerals_4.png'} alt="planet"></img>
        <h2>Boid Crystal</h2>
      </div>

      <div className="slide-item food">
        <img src={process.env.PUBLIC_URL + '/minerals_5.png'} alt="planet"></img>
        <h2>Food</h2>
      </div>

      <div className="slide-item water">
        <img src={process.env.PUBLIC_URL + '/minerals_6.png'} alt="planet"></img>
        <h2>Water</h2>
      </div>

      <div className="slide-item chemicals">
        <img src={process.env.PUBLIC_URL + '/minerals_7.png'} alt="planet"></img>
        <h2>Chemicals</h2>
      </div>

      <div className="slide-item steel">
        <img src={process.env.PUBLIC_URL + '/minerals_8.png'} alt="planet"></img>
        <h2>Steel</h2>
      </div>


      <div className="slide-item aluminium">
        <img src={process.env.PUBLIC_URL + '/minerals_9.png'} alt="planet"></img>
        <h2>Aluminium</h2>
      </div>


      <div className="slide-item unrefined-oil">
        <img src={process.env.PUBLIC_URL + '/minerals_10.png'} alt="planet"></img>
        <h2>Unrefined oil</h2>
      </div>


      <div className="slide-item fuel">
        <img src={process.env.PUBLIC_URL + '/minerals_11.png'} alt="planet"></img>
        <h2>Fuel</h2>
      </div>


      <div className="slide-item research">
        <img src={process.env.PUBLIC_URL + '/minerals_12.png'} alt="planet"></img>
        <h2>Research</h2>
      </div>

      </div>

</section>

{/* <section id="rarities">
    <h2>Mining facilities rarities</h2>
    <div class="rarity">
        <h3 class="trash">TRASH</h3>
        <h3 class="common">COMMON</h3>
        <h3 class="regular">REGULAR</h3>
        <h3 class="uncommon">UNCOMMON</h3>
        <h3 class="rare">RARE</h3>
        <h3 class="premium">PREMIUM</h3>
        <h3 class="epic">EPIC</h3>
        <h3 class="legendary">LEGENDARY</h3>
        <h3 class="mythic">MYTHIC</h3>
    </div>
</section> */}

<section id="exchange">
    <div class="contenedorExchange">
        <h2 className="titleBoid">The Boid Exchange</h2>
        <p className="pBoid">All resources gained can be sold or traded via the Boid Exchange. 	This exchange is an internal market designed to simulate supply and 	demand. The end goal of every player is to collect and extract Boid Crystals for the BOID they contain. The ways to get there are limitless. Users can deploy countless strategies to earn Boid Crystals. From simply forging the highest quality Boid Crystal mining facilities as possible to becoming a master of the market and trading for all your Boid Crystals  the sky's the limit.</p>
        <div class="exchangeImgs">
            <img src={process.env.PUBLIC_URL + '/exchange1.png'} alt=""></img>
            <img src={process.env.PUBLIC_URL + '/exchange2.png'} alt=""></img>
        </div>
    </div>
</section>

<section id="playerCards">
    <div class="contenedorTodoNft">
        <div class="contenedorNft">
            <div class="nftText">
                <h2 className="titleBoid">NFT Player Cards</h2>
            <p className="pBoid">Players can collect, mix, and fuse BoidVerse NFT cards to:
            </p>
            <ul className="pBoid">
                <li>Unlock deeper depths of the BoidVerse lore and the story within.
                </li>
                <li>Gain economic boosts depending on card type and rarity.
                </li>
                <li>Unlock new cards, mix existing cards, and climb the rarity scale to unlock exclusive boosts for your colony.
                </li>
            </ul>
            <p className="pBoid">Every player will be able to have an “Active Hand.” which is a set of cards that are producing benefits for their colonies. As players strategies differ, so will their Active Hand in order to boost and make their strategy more effective
            </p>
            <p className="pBoid">Players will be able to expand upon the game with a digital trading card game built on top of the BoidVerse. These NFTs support a dual purpose. Every card, through every rarity, will provide your colony with varying boosts in production depending on the card type, and rarity. In addition to boosting the economy all the cards will become functional parts of the BoidVerse card game when it becomes available in the future</p>
            </div>
            <div class="nftImg">
                <img src={process.env.PUBLIC_URL + '/NFTimg.png'} alt="nft"></img>
            </div>
        </div>
        <h3>Tech Trees</h3>
        <p className="pBoid treesText">Different species excel at different objectives. Each species has unique starter technologies and can gain an advantage in certain aspects of the game. As the species mingled so did their technologies. Years after the first Boid Arc the Boid have reached out into space learning about the different technologies that different species excelled at. Players will be able to choose different Boid that have chosen hosts of these different species and have learned their respective technologies. Trade with other species for their unique technologies to optimize your colony and conquer the stars.
    
        As players earn research they will be able to unlock blueprints to speed up construction of the buildings as well as upgrades to strengthen their colonies economy.
        </p>
    </div>
</section>

<section id="exploration">
    <div class="contenedorExploration">
        <div class="explorationImg">
            <img src={process.env.PUBLIC_URL + '/explorationImg.png'} alt="exploration img"></img>
        </div>
        <div class="explorationText">
            <h2 className="titleBoid">Exploration</h2>
            <p className="pBoid">Opening up the map of the BoidVerse colonies can now send their ships on longer expeditions to locate a vast expanse of asteroids rich in resources. Resources mined from asteroids are mined at an exponential rate compared to colony mining. Ships sent out on expeditions can randomly meet other players' ships on the map and engage in both trade or battle. The introduction of squad based combat and fleet mechanics.</p>
        </div>
    </div>
</section>

<section id="combat">
    <div class="contenedorCombat">
        <div class="combatText">
            <h2 className="titleBoid" >Ship to Ship Combat</h2>
            <p className="pBoid">Much like the game FTL players could use their shipyards in their colony to build ships. With the addition of Ship to ship combat additional buildings would become available to build for weapons, shields, asteroid mining equipment etc. Players can outfit their ships and send them on expeditions or challenge individual players. Players can bet resources, including Boid Crystals, on who will win when challenged. If sent on an expedition players ships will travel to the ends of the BoidVerse in search of Treasure. Players might encounter NPC pirates, mercenaries, asteroids rich in a given resource and more!
            </p>
            <p className="pBoid">Asteroids discovered need to be guarded to ensure other players do not attack for the asteroid rich resource. Team colonies must send ships that guard the asteroid until mined dry. Neighboring colonies will have a chance to discover the same asteroid and will be notified that they can contest the claim if they so desire.
            </p>
            <p className="pBoid">Players can hire mercenaries with Gold to protect their asteroids or hire them to contest a nearby asteroid.
            </p>
        </div>
        <div class="combatImg">
            <img src={process.env.PUBLIC_URL + '/combatImg.png'} alt="combat img"></img>
        </div>
    </div>
</section>


<section id="war">
    <div class="contenedorWar">
        <h2 className="titleBoid">The Interstellar Boid War</h2>
        <p className="pBoid">As colonies grow they will have the option to build a mothership and set out in the stars. While expensive these motherships can be the source of incredible riches. Motherships can respawn player ships and be sent out to begin territory control. Hexes will turn into the colonies chosen color and players’ ships assigned to the mothership will automatically patrol, protect, and mine any resources found within the influence of the mothership. Motherships rally the defenses as a swarm when resources in the colony territory is attacked by the various factions in the BoidVerse. Motherships can also be commanded to attack the territory of another colony to gain access to an important resource rich asteroids.
        </p>
        <div class="warImgs">
            <img src={process.env.PUBLIC_URL + '/warimg1.png'} alt="war1"></img>
            <img src={process.env.PUBLIC_URL + '/warimg2.png'} alt="war2"></img>
            <img src={process.env.PUBLIC_URL + '/warimg3.png'} alt="war3"></img>
        </div>
    </div>
</section>

<Footer />

    </div>
  )
}

export default TheUniverse